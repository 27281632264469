import { ThemeProvider, Button, SelectField, TextField, Alert, Link } from '@aws-amplify/ui-react'
import { RefObject, Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react'
import { MdLocalPostOffice } from 'react-icons/md'
import { FaCheck } from 'react-icons/fa'
import { IoMdInformationCircle } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { ShipmentsDiv } from '../helper-components'
import { OrderState, UpdateOrderInput, UpdateProductInput, ProductStatus, Shipment, Order, OrderSource, Product } from '../../API'
import { RootState } from '../../store'
import { useAppDispatch } from '../../store/hooks'
import { setItemsError, updateOrderAsync } from '../../store/itemsSlice'
import { setShipmentsSuccessMessage, setShipmetsErrorMessage, updateShipmentAsync } from '../../store/shippingSlice'
import { resetWooState, wooUpdateOrderAsync } from '../../store/woocommerceSlice'
import { Color, zptheme } from '../../theme'
import { calculateShippingCostsDHL, calculateShippingCostsDHLWP, calculateShippingCostsDPD, calculateShippingCostsGLS, calculateShippingCostsInline, calculateShippingCostsUPS, createInlineLink, getCheapestShippingServiceProvider, getDPDShippingDate, getPossibleShippingServiceProvider, getTrackingLink, getUniqueShipmentsOfOrder, inlinePalettePossible } from '../../utils/shipments-calc'
import { API, graphqlOperation, label } from 'aws-amplify'
import { getOrder } from '../../graphql/queries'
import { ServiceProvider, spEdgeValues } from '../../utils/Constants'
import { finishedItem, prettyDate, rePrintLabel } from '../../utils'
import { createDPDShippingLabel, Sender, DPDShippingLabelData, setshippingLabelError, createGLSShippingLabel, GLSShippingLabelData, createUPSShippingLabel, setShippingLabelSuccess, createDHLShippingLabel, ConsigneeCategory, cancelGLSShippingLabel } from '../../store/shippingLabelSlice'
import SimpleModal from '../helper-components/SimpleModal'
import { createDocument } from '../../store/printDocumentsSlice'
import { updateItemAsync } from '../../store/itemsSlice'
import ToolTipPopup from '../helper-components/ToolTipPopup'
import { calculateShippingCostsBursped } from '../../utils/shipments-bursped-calc'
import { sendMailTemplate } from '../../store/mailSlice'

const PrintRetoureLabelButton = lazy(() => import('./buttonsetc/PrintRetoureLabelButton'))

function ShipmentCard({ shipment }: { shipment: Shipment }) {
    const dispatch = useAppDispatch()
    const { items } = useSelector((state: RootState) => state.itemsState)
    const { noPickupDays, errorCodes, error } = useSelector((state: RootState) => state.shippingLabelState)
    const { email } = useSelector((state: RootState) => state.userState)

    const [spToolTip, setSpToolTip] = useState<any>('')
    const [newLength, setNewLength] = useState<string | undefined>(undefined)
    const [newWidth, setNewWidth] = useState<string | undefined>(undefined)
    const [newWeight, setNewWeight] = useState<string | undefined>(undefined)
    const [newHeight, setNewHeight] = useState<string | undefined>(undefined)
    const [newCosts, setNewCosts] = useState<number | undefined>(undefined)
    const [dpdDataCorrected, setDpdDataCorrected] = useState<DPDShippingLabelData | undefined>(undefined)
    const [burspedModalOpen, setBurspedModalOpen] = useState<boolean>(false)
    const [burspedParcelNumber, setBurspedParcelNumber] = useState<string | undefined>(undefined)
    const [inlineParcelNumber, setInlineParcelNumber] = useState<string | undefined>(undefined)
    const [inlineParcelNumberModalOpen, setInlineParcelNumberModalOpen] = useState<boolean>(false)
    const serviceProvider = shipment?.serviceProvider
    const shipmentItems = items.filter(el => el.productShipmentId === shipment.id || el.productRetoure_shipmentId === shipment.id)
    // add each quantity 
    const itemsCount = shipmentItems.reduce((acc, item) => acc + (item.quantity || 1), 0)
    // It was the plan to put items of many orders together but that never happend so this works
    const order = shipmentItems[0]?.order
    // and also this // maybe safe a sender in the shipment later if it could be from different contractors
    const contractor = shipmentItems[0]?.contractor
    const sender = contractor?.number as Sender
    const returnAddress = sender === 'F002' || sender === 'F009' || sender === 'F011' ? 'Achter de Weiden 10/22, 22869 Schenefeld' : 'Luruper Hauptstraße 54, 22547 Hamburg'

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    useEffect(() => {
        switch (serviceProvider) {
            case ServiceProvider.DPD:
                setSpToolTip(
                    <div style={{ fontSize: 'small' }}>
                        <div>max Gewicht: {spEdgeValues.DPD.weight} Kg</div>
                        <div>max Länge: {spEdgeValues.DPD.length} cm</div>
                        <div>max Gurtmaß: {spEdgeValues.DPD.gurtMaß} cm</div>
                    </div>
                )
                return
            case ServiceProvider.INLINE:
                setSpToolTip(
                    <div style={{ fontSize: 'small' }}>
                        <div>Speditionssendung</div>
                        <div>Ggf. mehrere Colli verwenden.Bei mehr als 60 Kg pro Colli kann ein 2 Mann Zuschlag von 25 € berechnet werden.</div>
                        <div>Bei mehr als 3 Colli pro Sendung werden 2 € pro Colli berechnet.</div>
                    </div>)
                return
            case ServiceProvider.UPS:
                setSpToolTip(
                    <div style={{ fontSize: 'small' }}>
                        <div>max Gewicht: {spEdgeValues.UPS.weight} Kg</div>
                        <div>max Länge: {spEdgeValues.UPS.length} cm</div>
                        <div>max Gurtmaß: {spEdgeValues.UPS.gurtMaß} cm</div>
                    </div>
                )
                return
            case ServiceProvider.GLS:
                setSpToolTip(
                    <div style={{ fontSize: 'small' }}>
                        <div>max Gewicht: {spEdgeValues.GLS.weight} Kg</div>
                        <div>max Länge: {spEdgeValues.GLS.length} cm</div>
                        <div>max Breite: {spEdgeValues.GLS.width} cm</div>
                        <div>max Gurtmaß: {spEdgeValues.GLS.gurtMaß} cm</div>
                    </div>
                )
                return
            case ServiceProvider.BURSPED:
                setSpToolTip(
                    <div>
                        <div>Speditionssendung</div>
                        <div>max. Abmessungen pro Colli: 240 x 200 x 220 cm</div>
                        <div>Einzelgewicht pro Packstück: max 150 Kg</div>
                        <div>Unterfahrfähigkeit bis zur Verwendungsstelle muss gegeben sein. Zum Abtragen pro Mann: max. 30Kg Einzelpackstück</div>
                    </div>
                )
                return
            case ServiceProvider.DHL:
                setSpToolTip(
                    <div style={{ fontSize: 'small' }}>
                        <div>max Gewicht: {spEdgeValues.DHL.weight} Kg</div>
                        <div>max Länge: {spEdgeValues.DHL.length} cm</div>
                        <div>max Breite: {spEdgeValues.DHL.width} cm</div>
                        <div>max Höhe: {spEdgeValues.DHL.height} cm</div>
                        <div>min Länge: {spEdgeValues.DHL.minLength} cm</div>
                        <div>min Breite: {spEdgeValues.DHL.minWidth} cm</div>
                        <div>min Höhe: {spEdgeValues.DHL.minHeight} cm</div>
                    </div>
                )
                return
            case ServiceProvider.DHLWP:
                setSpToolTip(
                    <div style={{ fontSize: 'small' }}>
                        <div>max Gewicht: {spEdgeValues.DHLWP.weight} Kg</div>
                        <div>max Länge: {spEdgeValues.DHLWP.length} cm</div>
                        <div>max Breite: {spEdgeValues.DHLWP.width} cm</div>
                        <div>max Höhe: {spEdgeValues.DHLWP.height} cm</div>
                        <div>min Länge: {spEdgeValues.DHLWP.minLength} cm</div>
                        <div>min Breite: {spEdgeValues.DHLWP.minWidth} cm</div>
                        <div>min Höhe: {spEdgeValues.DHLWP.minHeight} cm</div>
                    </div>
                )
                return
            default:
                setSpToolTip('')
                return
        }
    }, [serviceProvider])

    const handleKeyDown = (e: KeyboardEvent, nextRef: React.RefObject<HTMLInputElement>) => {
        if (e.key === "Tab") {
            e.preventDefault(); // Prevent default tab behavior
            nextRef.current?.focus(); // Focus the next input
        }
    };

    const escFunction = useCallback((event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setNewLength(undefined)
            setNewWidth(undefined)
            setNewWeight(undefined)
            setNewHeight(undefined)
            setNewCosts(undefined)
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    if (!shipment) {
        return null
    }

    const orderNumbers: (string | undefined)[] = []
    let shippingRevenues = 0
    const orderShippingMethods: string[] = []
    // calculate shipping revenues and add unique order numbers
    shipmentItems.forEach((currentItem: Product) => {
        const order = currentItem.order
        if (!orderNumbers.includes(order?.orderNumber)) {
            if (order?.shipping_method) orderShippingMethods.push(order?.shipping_method)
            orderNumbers.push(order?.orderNumber)
            if (order?.shipping_total) shippingRevenues += order?.shipping_total
            if (order?.shipping_tax) shippingRevenues += order.shipping_tax
            if (order?.fees && order?.fees?.length > 0) {
                order.fees.forEach(fee => shippingRevenues += fee?.amount || 0)
            }
        }
    })

    async function onClickConfirm() {        
        if (!shipmentItems) {
            return
        }
        if (!shipmentFinishable) {
            dispatch(setShipmetsErrorMessage('Die Sendung kann nicht abgeschlossen werden, da nicht alle Produkte fertig sind. Erstelle ggf. eine weitere Sendung für die nicht fertigen Produkte.'))
            return
        }
        const shippedAt = new Date().toISOString()
        const variables = {
            input: {
                id: shipment.id,
                length,
                width,
                weight,
                gurtMass,
                serviceProvider,
                costs,
                shippedAt
            }
        }

        const updateShipmentAction = await dispatch(updateShipmentAsync(variables))
        if (updateShipmentAction.type === updateShipmentAsync.rejected.type) {
            // an errormessage to try again will appear in header
            return
        }

        // confirm packing of each product
        for (const product of shipmentItems) {
            if (finishedItem(product)) {
                // in case not all action had succeeded on the first try, try again only for unfinished items
                continue
            }
            // it might be that items where reset and shipped than so we set status billed if it was already billed
            const status = product.billedAt ? ProductStatus.billed : ProductStatus.packing_confirmed
            const variables = {
                input: {
                    id: product.id,
                    status,
                    contractor_status: `${ product.productContractorId }-${ status }`,
                    finishedAt: shippedAt,
                    currentTask: null,
                    contractor_currentTask: null
                } as unknown as UpdateProductInput
            }
            const updateItemAction = await dispatch(updateItemAsync(variables))
            // on error break up
            if (updateItemAction.type === updateItemAsync.rejected.type) {
                return
            }
        }

        const orderIds = [...new Set(shipmentItems.map(item => item.productOrderId))]

        for (const orderId of orderIds) {
            const orderResponse = await API.graphql(graphqlOperation(getOrder, { id: orderId }))
            //@ts-ignore
            const updatedOrder = orderResponse.data.getOrder
            if (updatedOrder.state === OrderState.finished) {
                // if items has been reset and are now finished we don't want to finish the order again
                continue
            }
            // unique shipments of order
            if (updatedOrder.source === OrderSource.woocommerce) {
                const shipmentsOfOrder = getUniqueShipmentsOfOrder(updatedOrder)
                // save shipments as meta data in woocommerce order
                await (dispatch(wooUpdateOrderAsync({ orderNumber: updatedOrder.orderNumber, data: { meta_data: [{ key: 'zp_shipments', value: shipmentsOfOrder }] } })))
            }
            const allConfirmed = updatedOrder.products.items.every(finishedItem)
            if (allConfirmed) {
                // finish order in woo on error break up
                if (!updatedOrder.source || updatedOrder.source === OrderSource.woocommerce) {
                    const response = await dispatch(wooUpdateOrderAsync({ orderNumber: updatedOrder.orderNumber, data: { status: 'completed' } }))
                    //@ts-ignore
                    if (response.payload.statusCode !== 200 && process.env.REACT_APP_ENV === 'prod') {
                        // ignore the error in dev environment because often the order doesn't exist in woocommerce staging
                        return
                    }
                }

                // finish order in ddb if all items are confirmed and break up on error
                const variables = {
                    input: {
                        id: updatedOrder.id,
                        finishedAt: new Date().toISOString(),
                        state: OrderState.finished
                    } as unknown as UpdateOrderInput
                }
                const updateOrderAction = await dispatch(updateOrderAsync(variables))
                if (updateOrderAction.type === updateOrderAsync.rejected.type) {
                    return
                }

                // send Mail to customer for rekla orders and channel information if order is not from woocommerce
                if (updatedOrder.source !== OrderSource.woocommerce && updatedOrder.source !== OrderSource.rekla) {
                    // send a notification to the channel if the order is not from woocommerce so the orders can be finished manually, e.g in etsy
                    // most of the time there are only one item per order and even if not amazon and etsy only want one tracking number
                    const firstOrderItem = updatedOrder.products.items[0]
                    const init = {
                        body: {
                            payload: {
                                orderNumber: updatedOrder.orderNumber,
                                customer_first_name: updatedOrder.customer.first_name,
                                orderSource: updatedOrder.source,
                                TrackID: firstOrderItem.shipment?.parcelNumber || firstOrderItem.shipment?.trackID,
                                serviceProvider: firstOrderItem.shipment?.serviceProvider,
                                contractor: firstOrderItem.contractor?.name
                            },
                            webhookURL: 'https://flow.zoho.eu/20066910136/flow/webhook/incoming?zapikey=1001.ee4d9255fd96c5050f29e764b9e6275b.7c5b3b3712be5ee98726e7f902ce91e3&isdebug=false'
                        }
                    }
                    await API.post('zohoapi', '/triggerWebHook', init)
                }
                if (order && order?.source === OrderSource.rekla) {
                    notifyCustomerOnOrderFinished(updatedOrder)
                }
            }
        }
        dispatch(setShipmentsSuccessMessage(`Sendung Nummer ${ shipment.parcelNumber || shipment.trackID || shipment.id } wurde erfolgreich fertiggestellt`))
    }

    async function notifyCustomerOnOrderFinished(order: Order) {
        if (!order.customer || !order.customer.email) {
            dispatch(setShipmetsErrorMessage('Die Kundeninformationen fehlen, daher wurde keine E-Mail Benachrichtigung an den Kunden/ die Kundin versendet'))
            return
        }
        let shipping_infos = `Deine Sendung macht sich noch heute auf den Weg zu dir.`
        if (getTrackingLink(shipment)) {
            // we don't have a tracking link for Spedi Shipments
            shipping_infos += `<br><br><a href='${ getTrackingLink(shipment) }'>Hier</a> kannst du dein Paket verfolgen.`
        }
        // send order shipped notification to customer (because we are in ShipmentCard order is shipped not picked up or delivered by zp)
        const data = {
            template_key: '13ef.53e3308d7370c518.k1.0857ed60-3f6d-11ef-af7d-5254006dcfe6.190a140d736',
            template_name: 'order_ready',
            // subject is required but not used because subject is defined in the template
            subject: `Deine Zuschnittprofi.de Bestellung #${ order.orderNumber } ist fertig!`,
            email: order.customer.email,
            merge_info: {
                orderNumber: order.orderNumber,
                first_name: order.customer.first_name,
                names: shipmentItems.sort((a, b) => (a.position || 0) - (b.position || 0)).map(i => i?.name).join('<br><br>'),
                quantities: shipmentItems.sort((a, b) => (a.position || 0) - (b.position || 0)).map(i => i?.quantity).join('<br><br>'),
                shipping_infos
            }
        }
        // send email
        await dispatch(sendMailTemplate(data))
    }

    async function onClickReturnConfirm() {
        if (!shipmentItems) {
            return
        }
        const shippedAt = new Date().toISOString()
        const variables = {
            input: {
                id: shipment.id,
                length,
                width,
                weight,
                gurtMass,
                serviceProvider,
                costs,
                shippedAt,
            }
        }
        await dispatch(updateShipmentAsync(variables))
    }

    async function setLabelPrinted() {
        if (!shipmentItems) {
            return
        }
        for (const product of shipmentItems) {
            if (product.status !== ProductStatus.ready_to_pack || product.shipping_label_printed) {
                continue
            }
            const variables = {
                input: {
                    id: product.id,
                    shipping_label_printed: true,
                }
            }
            await dispatch(updateItemAsync(variables))
        }
    }

    async function onclickShippingLabel() {
        dispatch(setshippingLabelError(undefined))
        dispatch(setShippingLabelSuccess(undefined))
        if (!shipmentItems[0].orderNumber || !order) {
            return
        }
        if (shipment.labelData) {
            console.log('reprint label')
            rePrintLabel({ shipment, order })
            setLabelPrinted()
            return
        }
        dispatch(setshippingLabelError(undefined))
        dispatch(setShippingLabelSuccess(undefined))
        setLabelPrinted()
        switch (serviceProvider) {
            case ServiceProvider.UPS:
                await navigator.clipboard.writeText(shipmentItems[0]?.orderNumber)
                printUPSLabel()
                break;
            case ServiceProvider.GLS:
                await navigator.clipboard.writeText(shipmentItems[0]?.orderNumber)
                printGLSLabel()
                break;
            case ServiceProvider.BLANKO:
                await navigator.clipboard.writeText(shipmentItems[0]?.orderNumber)
                printBlankLabel()
                break;
            case ServiceProvider.BURSPED:
                await navigator.clipboard.writeText(shipmentItems[0]?.orderNumber)
                setBurspedModalOpen(true)
                break;
            case ServiceProvider.INLINE:
                if (!order) {
                    return dispatch(setshippingLabelError(['Die Auftragsnummer fehlt, bitte kontaktiere die Administratorin.']))
                }
                await navigator.clipboard.writeText(order.orderNumber)
                const { first_name, last_name, street, house_number, zip, city, email, phone } = order.customer
                if (!first_name || !last_name || !street || !house_number || !zip || !city || !email || !phone) {
                    return dispatch(setshippingLabelError(['Es kann kein Inline Label erstellt werden, da Daten fehlen, bitte überprüfe die Adresse.']))

                }
                setInlineParcelNumberModalOpen(true)
                // IMPORTANT the link only works with this extension https://chrome.google.com/webstore/detail/user-javascript-and-css/nbhcbdghjpllgmfilhnhkllmkecfmpld
                const speditionLink = createInlineLink(order.customer, length, width, height, weight, order.orderNumber)
                window.open(speditionLink)
                dispatch(resetWooState())
                break;
            case ServiceProvider.DPD:
                printDPDLabel()
                break;
            case ServiceProvider.DHL:
            case ServiceProvider.DHLWP:
                printDHLLabel()
                break;
            default:
                await navigator.clipboard.writeText(shipmentItems[0]?.orderNumber)
                dispatch(setshippingLabelError(['Es kann kein Label erstellt werden, da kein Versanddienstleister ausgewählt wurde.']))
                break;
        }
    }

    async function printDHLLabel() {
        if (!order) {
            dispatch(setshippingLabelError(['Das DHL Label konnte nicht erstellt werden, bitte kontaktiere die Administratorin.']))
            return
        }
        const { first_name, last_name, street, house_number, zip, city, country, email, phone, company, add, dhl_lockerId, dhl_postNumber } = order.customer
        if (!first_name || !last_name || !zip || !city || !email) {
            dispatch(setshippingLabelError(['Das DHL Label konnte nicht erstellt werden, da Daten fehlen, bitte überprüfe die Adresse.']))
            return
        }
        if (street && !house_number && !dhl_lockerId) {
            dispatch(setshippingLabelError(['Das DHL Label konnte nicht erstellt werden, bitte überprüfe die Hausnummer.']))
            return
        }
        if (!street && house_number && !dhl_lockerId) {
            dispatch(setshippingLabelError(['Das DHL Label konnte nicht erstellt werden, Straße oder Packstation wird benötigt.']))
            return
        }
        if (!street && !dhl_lockerId) {
            dispatch(setshippingLabelError(['Das DHL Label konnte nicht erstellt werden, Straße und Hausnummer oder Packstation wird benötigt.']))
            return
        }
        if (dhl_lockerId && !dhl_postNumber) {
            dispatch(setshippingLabelError(['Das DHL Label konnte nicht erstellt werden, bitte überprüfe die Packstation und Postnummer.']))
            return
        }
        let consignee = {
            name1: `${ first_name } ${ last_name }`,
            addressStreet: street && house_number ? `${ street } ${ house_number }` : '',
            postalCode: zip,
            city,
            country: country === 'DE' ? 'DEU' : country || 'DEU',
            email
        }

        if (dhl_lockerId) {
            consignee = {
                // @ts-ignore
                name: `${ first_name } ${ last_name }`,
                lockerID: dhl_lockerId,
                postNumber: dhl_postNumber,
                postalCode: zip,
                city,
                country: country === 'DE' ? 'DEU' : country || 'DEU',
                email
            }
        }

        const dhlLabelData = {
            referenceNumber: order.orderNumber,
            contractor,
            consignee,
            details: {
                dim: {
                    uom: 'cm',
                    height,
                    length,
                    width
                },
                weight: {
                    uom: 'kg',
                    value: weight
                }
            },
            product: serviceProvider === ServiceProvider.DHLWP ? 'V62WP' : 'V01PAK'
        }
        if (company) {
            dhlLabelData.consignee.name1 = company
            // @ts-ignore
            dhlLabelData.consignee.name2 = `${ first_name } ${ last_name }`
        }
        // @ts-ignore
        if (add) dhlLabelData.consignee.name3 = add
        const printAction = await dispatch(createDHLShippingLabel(dhlLabelData))
        if (printAction.type === createDHLShippingLabel.fulfilled.type) {
            // @ts-ignore
            const parcelNumber = printAction.payload?.parcelNumber
            if (parcelNumber) {
                const shipmentVar = {
                    input: {
                        id: shipment.id,
                        parcelNumber,
                        labelData: {
                            // @ts-ignore
                            base64: printAction.payload?.base64,
                            // @ts-ignore
                            format: printAction.payload?.labelFormat
                        }
                    }
                }
                dispatch(updateShipmentAsync(shipmentVar))
            }
            dispatch(resetWooState())
        }
    }

    async function printUPSLabel() {
        if (!order) {
            dispatch(setshippingLabelError(['Das UPS Label konnte nicht erstellt werden, bitte kontaktiere die Administratorin.']))
            return
        }
        const { first_name, last_name, street, house_number, zip, city, country, email, phone, company, add } = order.customer
        if (!first_name || !last_name || !street || !house_number || !zip || !city) {
            dispatch(setshippingLabelError(['Das UPS Label konnte nicht erstellt werden, da Daten fehlen, bitte überprüfe die Adresse.']))
            return
        }
        const adressLines = []
        adressLines.push(`${ street } ${ house_number }`)
        if (company) adressLines.push(company)
        if (add) adressLines.push(add)
        const upsLabelData = {
            ReferenceNumber: order.orderNumber,
            ShipTo: {
                Name: `${ first_name } ${ last_name }`,
                EMailAddress: email,
                Address: {
                    AddressLine: adressLines,
                    City: city,
                    PostalCode: zip,
                    CountryCode: country || 'DE'
                }
            },
            Package: {
                Description: shipmentItems.map(i => i.name).join(', '),
                Packaging: {
                    Code: "02"
                },
                Dimensions: {
                    UnitOfMeasurement: {
                        "Code": "CM"
                    },
                    Length: length.toString(),
                    Width: width.toString(),
                    Height: height.toString()
                },
                PackageWeight: {
                    UnitOfMeasurement: {
                        Code: "KGS"
                    },
                    Weight: weight.toString()
                }
            },
            location: sender || 'F001',
        }
        // @ts-ignore
        if (phone) upsLabelData.ShipTo.Phone = { Number: phone }
        const printAction = await dispatch(createUPSShippingLabel(upsLabelData))
        if (printAction.type === createUPSShippingLabel.fulfilled.type) {
            // @ts-ignore
            const trackID = printAction.payload?.trackID
            // @ts-ignore
            const parcelNumber = printAction.payload?.parcelNumber
            if (trackID) {
                const shipmentVar = {
                    input: {
                        id: shipment.id,
                        trackID,
                        parcelNumber,
                        labelData: {
                            // @ts-ignore
                            base64: printAction.payload?.base64,
                            // @ts-ignore
                            format: printAction.payload?.labelFormat
                        }
                    }
                }
                dispatch(updateShipmentAsync(shipmentVar))
            }
            dispatch(resetWooState())
        }
    }


    async function printBlankLabel() {
        if (!order) {
            dispatch(setshippingLabelError(['Das Label konnte nicht erstellt werden, bitte kontaktiere die Administratorin.']))
            return
        }
        const { first_name, last_name, street, house_number, zip, city, country, company, add } = order.customer
        if (!first_name || !last_name || !street || !house_number || !zip || !city) {
            dispatch(setshippingLabelError(['Das Label konnte nicht erstellt werden, da Daten fehlen, bitte überprüfe die Adresse.']))
            return
        }
        let additionalinfos = company ? ` ${ company }` : ''
        additionalinfos += add ? ` ${ add }` : ''

        const data = {
            sender: returnAddress,
            first_name,
            last_name,
            address_1: `${ street } ${ house_number }`,
            address_2: additionalinfos,
            postcode: zip,
            city: city,
            country: country || 'DE',
            order_number: order.orderNumber
        }
        await dispatch(createDocument({ data, outputName: `labels_${ order.orderNumber }-${ last_name }.pdf`, templateName: 'samples/unstamped_letter.docx' }))
    }

    async function cliqBurspedLabelRequest() {
        if (!order) {
            return dispatch(setshippingLabelError(['Die Auftragsnummer fehlt, bitte kontaktiere die Administratorin.']))
        }
        const { first_name, last_name, street, house_number, zip, city, email, phone, company, add, country } = order.customer
        if (!first_name || !last_name || !street || !house_number || !zip || !city || !email || !phone || !country) {
            return dispatch(setshippingLabelError(['Es kann kein Label erstellt werden, da Daten fehlen, bitte überprüfe die Adresse.']))

        }
        let additionalinfos = company ? ` ${ company }` : ''
        additionalinfos += add ? ` ${ add }` : ''
        // trigger webhook with zoho api
        const init = {
            body: {
                payload: {
                    sender: contractor?.name,
                    first_name,
                    last_name,
                    address_1: `${ street } ${ house_number }`,
                    address_2: additionalinfos,
                    postcode: zip,
                    city: city,
                    country,
                    phone,
                    order_number: order.orderNumber,
                    length,
                    width,
                    height,
                    weight
                },
                webhookURL: 'https://flow.zoho.eu/20066910136/flow/webhook/incoming?zapikey=1001.14639fe5ec3fe7a399804f63e5e71303.5be2d8b6cd7e36f900ac3b7886d4dade&isdebug=false'
            }
        }
        const response = await API.post('zohoapi', '/triggerWebHook', init)
        if (response === 'Message sent to webhook successfully!') {
            dispatch(setShipmentsSuccessMessage(`Das Label wurde per cliq angefordert`))
        } else {
            dispatch(setshippingLabelError(['Das Label konnte nicht angefordert werden, bitte kontaktiere die Administratorin.']))
        }
    }

    async function printGLSLabel() {
        if (!order) {
            dispatch(setshippingLabelError(['Das GLS Label konnte nicht erstellt werden, bitte kontaktiere die Administratorin.']))
            return
        }
        const { first_name, last_name, street, house_number, zip, city, country, email, phone, company, add } = order.customer
        if (!first_name || !last_name || !street || !house_number || !zip || !city || !country || !email) {
            dispatch(setshippingLabelError(['Das GLS Label konnte nicht erstellt werden, da Daten fehlen, bitte überprüfe die Adresse.']))
            return
        }
        let additionalinfos = company ? ` ${ company }` : ''
        additionalinfos += add ? ` ${ add }` : ''
        const glsLabelData = {
            shipment: {
                ShipmentReference: [
                    order.orderNumber
                ],
                ShipmentUnit: [{
                    Weight: weight
                }],
                Product: "PARCEL",
                Consignee: {
                    Category: ConsigneeCategory.PRIVATE,
                    Address: {
                        Name1: `${ first_name } ${ last_name }`,
                        Name2: additionalinfos,
                        CountryCode: country || 'DE',
                        ZIPCode: zip,
                        City: city,
                        Street: street,
                        StreetNumber: house_number,
                        eMail: email,
                        FixedLinePhonenumber: phone,
                        ContactPerson: `${ first_name } ${ last_name }`
                    }
                }
            },
            contractor,
        }
        const printAction = await dispatch(createGLSShippingLabel(glsLabelData))
        if (printAction.type === createGLSShippingLabel.fulfilled.type) {
            // @ts-ignore
            const parcelNumber = printAction.payload?.parcelNumber
            // @ts-ignore
            const trackID = printAction.payload?.trackID

            if (parcelNumber && trackID) {
                const shipmentVar = {
                    input: {
                        id: shipment.id,
                        parcelNumber,
                        trackID,
                        labelData: {
                            // @ts-ignore
                            base64: printAction.payload?.base64 || '',
                            // @ts-ignore
                            format: printAction.payload?.labelFormat || ''
                        }
                    }
                }
                dispatch(updateShipmentAsync(shipmentVar))
            }
            dispatch(resetWooState())
        }
    }

    async function printDPDLabel() {
        if (!order || !sender) {
            dispatch(setshippingLabelError(['Das DPD Label konnte nicht erstellt werden, bitte kontaktiere die Administratorin.']))
            return
        }
        const { first_name, last_name, street, house_number, zip, city, country, email, phone, company, add } = order.customer
        if (!first_name || !last_name || !street || !house_number || !zip || !city) {
            dispatch(setshippingLabelError(['Das DPD Label konnte nicht erstellt werden, da Daten fehlen, bitte überprüfe die Adresse.']))
            return
        }
        const dpdLabelData = {
            OrderAction: "startOrder",
            OrderSettings: {
                ShipDate: getDPDShippingDate(noPickupDays),
                LabelSize: "PDF_A6",
                LabelStartPosition: "UpperLeft"
            },
            OrderDataList: [
                {
                    ShipAddress: {
                        Company: company,
                        Gender: "",
                        Salutation: '',
                        FirstName: first_name,
                        LastName: add ? `${ last_name } ${ add }` : last_name,
                        Name: "",
                        Street: street.trim(),
                        HouseNo: house_number,
                        ZipCode: zip,
                        City: city,
                        Country: country,
                        State: "",
                        Phone: phone,
                        Mail: email
                    },
                    ParcelData: {
                        YourInternalID: order.orderNumber,
                        Content: "Zuschnitte",
                        Weight: weight,
                        Reference1: order.orderNumber,
                        Reference2: "",
                        ShipService: "Classic_Predict"
                    }
                }
            ]
        } as unknown as DPDShippingLabelData
        const printAction = await dispatch(createDPDShippingLabel({ parcelData: dpdLabelData, contractor }))
        //@ts-ignore
        if (printAction.payload && JSON.stringify(printAction.payload).includes('CLOUD_ADDRESS')) {
            setDpdDataCorrected(dpdLabelData)
        }
        if (printAction.type === createDPDShippingLabel.rejected.type) {
            return
        }
        //@ts-ignore
        const parcelNumber = printAction.payload.parcelNumber
        if (parcelNumber) {
            const shipmentVar = {
                input: {
                    id: shipment.id,
                    parcelNumber,
                    labelData: {
                        // @ts-ignore
                        base64: printAction.payload?.base64,
                        // @ts-ignore
                        format: printAction.payload?.labelFormat
                    }
                }
            }
            dispatch(updateShipmentAsync(shipmentVar))
        }
        dispatch(resetWooState())
    }

    async function printCorrectedDPDLabel() {
        if (!dpdDataCorrected || !sender) {
            dispatch(setshippingLabelError(['Label kann nicht erstellt werden, weil Daten fehlen, bitte überprüfe deine Eingabe']))
            return
        }
        const printAction = await dispatch(createDPDShippingLabel({ parcelData: dpdDataCorrected, contractor }))
        //@ts-ignore
        const parcelNumber = printAction.payload.parcelNumber
        if (parcelNumber) {
            const shipmentVar = {
                input: {
                    id: shipment.id,
                    parcelNumber
                }
            }
            dispatch(updateShipmentAsync(shipmentVar))
        }
        dispatch(resetWooState())
        dispatch(setshippingLabelError(undefined))
        setDpdDataCorrected(undefined)
    }

    async function updateserviceProvider(serviceProvider: string) {
        if (shipment.serviceProvider === ServiceProvider.GLS && shipment.trackID) {
            // cancel gls label because otherwise it will cost money
            const cancelAction = await dispatch(cancelGLSShippingLabel(shipment.trackID))
            if (cancelAction.payload?.result === 'SCANNED') {
                dispatch(setItemsError('Das GLS Label wurde bereits vom Fahrer gescannt. Du kannst den Versender nicht mehr ändern.'))
                return
            }
        }
        const variables = {
            input: {
                id: shipment.id,
                serviceProvider,
                costs,
                edited: new Date().toISOString(),
                parcelNumber: null,
                trackID: null,
                labelData: null
            }
        }
        switch (serviceProvider) {
            case ServiceProvider.INLINE:
                variables.input.costs = parseFloat(calculateShippingCostsInline(weight, length, width, itemsCount))
                break;
            case ServiceProvider.UPS:
                variables.input.costs = parseFloat(calculateShippingCostsUPS(length, width, weight, height))
                break;
            case ServiceProvider.DPD:
                variables.input.costs = parseFloat(calculateShippingCostsDPD(length, width, weight))
                break;
            case ServiceProvider.GLS:
                variables.input.costs = parseFloat(calculateShippingCostsGLS(length, width, weight))
                break;
            case ServiceProvider.BLANKO:
                variables.input.costs = 0
                break;
            case ServiceProvider.DHL:
                variables.input.costs = parseFloat(calculateShippingCostsDHL())
                break;
            case ServiceProvider.DHLWP:
                variables.input.costs = parseFloat(calculateShippingCostsDHLWP())
                break;
            case ServiceProvider.BURSPED:
                variables.input.costs = parseFloat(calculateShippingCostsBursped(length, width, height, weight, order?.customer.zip || ''))
                break;
            default:
                variables.input.costs = 0
                break;
        }
        await dispatch(updateShipmentAsync(variables))
    }

    async function updateMeasurements() {
        if (!shipment.id) {
            return
        }
        const updateLength = newLength ? parseFloat(newLength.replace(',', '.')) : shipment.length
        const updateWidth = newWidth ? parseFloat(newWidth.replace(',', '.')) : shipment.width
        const updateWeight = newWeight ? parseFloat(newWeight.replace(',', '.')) : shipment.weight
        const updateHeight = newHeight ? parseFloat(newHeight.replace(',', '.')) : height
        const updateGurtMass = updateHeight * 2 + updateWidth * 2 + updateLength
        let possibleServiceProviders = getPossibleShippingServiceProvider(updateLength, updateWidth, updateWeight, updateGurtMass, updateHeight, shipmentItems.length, order?.customer.zip || '', order?.shipping_method, order?.customer.dhl_lockerId)
        let serviceProvider = getCheapestShippingServiceProvider(possibleServiceProviders)
        // Testweise für 4 Wochen GLS ggü. DPD bevorzugen, weil die Transportschäden bei Holz zunehmen
        const firstContractor = items[0].contractor?.name
        if (firstContractor === 'HAMBURGERHOLZMANUFAKTUR' || firstContractor === 'ZP-SCHENEFELD') {
            if (serviceProvider.name === ServiceProvider.DPD) {
                const maxValue = Math.max(updateLength, updateWidth)
                const minValue = Math.min(updateLength, updateWidth)
                if (maxValue >= 76.5 && minValue >= 51) {
                    serviceProvider = { name: ServiceProvider.GLS, costs: parseFloat(calculateShippingCostsGLS(updateLength, updateWidth, updateWeight)) }
                }
            }
        }
        let suggest_splitting = false
        if (serviceProvider.name === ServiceProvider.INLINE) {
            if (shipmentItems.length > 1 || (shipmentItems[0].quantity && shipmentItems[0].quantity > 1)) {
                suggest_splitting = true
            }
        }
        const variables = {
            input: {
                id: shipment.id,
                length: updateLength,
                width: updateWidth,
                weight: updateWeight,
                height: updateHeight,
                gurtMass: updateGurtMass,
                serviceProvider: serviceProvider.name,
                costs: serviceProvider.costs,
                calculatedCosts: null,
                edited: new Date().toISOString(),
                suggest_splitting,
                overrule_splitting_user: null
            }
        }
        await dispatch(updateShipmentAsync(variables))
        setNewLength(undefined)
        setNewWidth(undefined)
        setNewWeight(undefined)
        setNewHeight(undefined)
    }

    async function saveInlineParcelNumber() {
        const variables = {
            input: {
                id: shipment.id,
                parcelNumber: inlineParcelNumber
            }
        }
        await dispatch(updateShipmentAsync(variables))
        setInlineParcelNumber(undefined)
        setInlineParcelNumberModalOpen(false)
    }

    async function saveBurspedParcelNumber() {
        const variables = {
            input: {
                id: shipment.id,
                parcelNumber: burspedParcelNumber
            }
        }
        await dispatch(updateShipmentAsync(variables))
        setBurspedParcelNumber(undefined)
        setBurspedModalOpen(false)
    }

    async function ignoreSplittingSuggestion() {
        const variables = {
            input: {
                id: shipment.id,
                overrule_splitting_user: email
            }
        }
        await dispatch(updateShipmentAsync(variables))
    }

    async function updateCosts() {
        const variables = {
            input: {
                id: shipment.id,
                costs: newCosts
            }
        }
        await dispatch(updateShipmentAsync(variables))
        setNewCosts(undefined)
    }

    const height = shipment.height
    const weight = shipment.weight
    const length = shipment.length
    const width = shipment.width
    const gurtMass = shipment.gurtMass


    const shippingServiceProviders = getPossibleShippingServiceProvider(length, width, weight, gurtMass, height, shipmentItems.length, order?.customer.zip || '', order?.shipping_method, order?.customer.dhl_lockerId)

    const costs = shipment.costs

    const shipmentFinishable = shipmentItems.every(p => (p.status === ProductStatus.ready_to_pack || p.status === ProductStatus.packing_confirmed) && p.shipping_label_printed) && (!shipment.suggest_splitting || shipment.overrule_splitting_user)
    const shipmentNumber = shipment.parcelNumber || shipment.trackID || shipment.id

    return (
        <ThemeProvider theme={zptheme}>
            <ShipmentsDiv id="shipment-card">
                {shipment.suggest_splitting &&
                    <Alert
                        style={{ marginTop: '0.5rem' }}
                        fontSize={'small'}
                        height='2.5em'
                        variation='error'
                        hasIcon={false}
                        isDismissible={false} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                        <ToolTipPopup tipText={'prüfe ob die Versandkosten reduziert werden können, wenn die Sendung in mehrere kleinere Pakete aufgeteilt wird.'}
                            trigger={<div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, alignSelf: 'center' }}>Aufteilung der Sendung prüfen!</div>
                                <Button
                                    size='small'
                                    maxHeight={'2em'}
                                    style={{
                                        backgroundColor: Color.red, color: 'white',
                                        borderColor: Color.red
                                    }}
                                    isDisabled={shipment.overrule_splitting_user !== null}
                                    onClick={ignoreSplittingSuggestion} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    {shipment.overrule_splitting_user ? <FaCheck /> : 'ign.'}
                                </Button>
                            </div>}
                        />
                    </Alert>
                }
                <div style={{ padding: '0.5em' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                        <div>
                            {shipment.typename === 'Shipment' ? (
                                <ToolTipPopup
                                    tipText="Versand Label Drucken"
                                    trigger={
                                        <Button
                                            id={`shipping-label-button-${ shipment.id }`}
                                            size={'small'}
                                            isDisabled={newHeight || newLength || newWidth || newWeight ? true : false}
                                            onClick={() => onclickShippingLabel()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                            <MdLocalPostOffice />
                                        </Button>
                                    } />)
                                : (
                                    <Suspense fallback={<Button size={'small'} isDisabled={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}><MdLocalPostOffice /></Button>} >
                                        <PrintRetoureLabelButton shipment={shipment} disabled={false} />
                                    </Suspense>
                                )}
                            {/* <SimpleModal open={burspedModalOpen} confirmAction={printBurspedLabel} dismissAction={() => setBurspedModalOpen(false)} okBtnText='Ja'>
                                <div>Möchtest du BurSped für die Sendung zum Auftrag #{order?.orderNumber} jetzt beauftragen?</div>
                            </SimpleModal> */}
                            <SimpleModal
                                open={inlineParcelNumberModalOpen}
                                dismissAction={() => {
                                    setInlineParcelNumber(undefined);
                                    setInlineParcelNumberModalOpen(false)
                                }}
                                okBtnText='Speichern'
                                confirmBtnDisabled={!inlineParcelNumber || inlineParcelNumber.length < 10}
                                confirmAction={saveInlineParcelNumber}
                                hideCancelBtn={true}>
                                <div style={{ textAlign: 'center' }}>Inline Sendungsnummer:</div>
                                <TextField
                                    autoFocus
                                    value={inlineParcelNumber}
                                    onChange={(e: any) => setInlineParcelNumber(e.currentTarget.value)}
                                    label={undefined}
                                />
                            </SimpleModal>
                            <SimpleModal open={burspedModalOpen}
                                confirmAction={saveBurspedParcelNumber}
                                dismissAction={() => { setBurspedModalOpen(false); setBurspedParcelNumber(undefined) }}
                                okBtnText='speichern'
                                confirmBtnDisabled={!burspedParcelNumber}
                                hideCancelBtn={true}>
                                <div style={{ display: 'grid', textAlign: 'center' }}>
                                    <div>Das Sendung muss im Bursped Portal angemeldet werden.</div>
                                    <div style={{ height: '0.5em' }} />
                                    <Link
                                        onClick={() => window.open('https://gembadocs.com/view-standard-operation/ca3612b91-30da495b8758-1723548964')}
                                        children={<div>Hier geht's zur Anleitung.</div>}
                                        placeholder={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined} />
                                    <div style={{ height: '0.5em' }} />
                                    <Link
                                        onClick={() => window.open('https://bursped.de/sendungserfassung/')}
                                        children={<div>https://bursped.de/sendungserfassung/</div>}
                                        placeholder={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined} />
                                    <div style={{ height: '0.5em' }} />
                                    <div style={{ textAlign: 'center' }}>Bursped Sendungsnummer:</div>
                                    <TextField
                                        autoFocus
                                        value={burspedParcelNumber}
                                        onChange={(e: any) => setBurspedParcelNumber(e.currentTarget.value)}
                                        label={undefined}
                                    />
                                </div>
                            </SimpleModal>
                            <SimpleModal open={errorCodes ? errorCodes?.length > 0 && dpdDataCorrected !== undefined : false}
                                confirmAction={printCorrectedDPDLabel}
                                dismissAction={() => { dispatch(setshippingLabelError(undefined)); setDpdDataCorrected(undefined) }}
                                style={{ width: '50em' }}>
                                {error?.map((el, index) => <Alert variation='error' key={index} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{el}</Alert>)}
                                {dpdDataCorrected?.OrderDataList.map((orderData, index) =>
                                    <div key={index}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Firma</div>
                                            <div style={{ flex: 2 }}>
                                                <TextField label={undefined} defaultValue={orderData.ShipAddress.Company}
                                                    onChange={(e: any) => {
                                                        const currentData = dpdDataCorrected.OrderDataList[index]
                                                        if (currentData) {
                                                            currentData.ShipAddress.Company = e.currentTarget.value
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>Vorname</div>
                                            <div style={{ flex: 2 }}>
                                                <TextField label={undefined} defaultValue={orderData.ShipAddress.FirstName}
                                                    onChange={(e: any) => {
                                                        const currentData = dpdDataCorrected.OrderDataList[index]
                                                        if (currentData) {
                                                            currentData.ShipAddress.FirstName = e.currentTarget.value
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>Nachname</div>
                                            <div style={{ flex: 2 }}>
                                                <TextField label={undefined} defaultValue={orderData.ShipAddress.LastName}
                                                    onChange={(e: any) => {
                                                        const currentData = dpdDataCorrected.OrderDataList[index]
                                                        if (currentData) {
                                                            currentData.ShipAddress.LastName = e.currentTarget.value
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                                                Straße + Nr.</div>
                                            <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                                                <div style={{ flex: 1 }}>
                                                    <TextField label={undefined} defaultValue={orderData.ShipAddress.Street}
                                                        onChange={(e: any) => {
                                                            const currentData = dpdDataCorrected.OrderDataList[index]
                                                            if (currentData) {
                                                                currentData.ShipAddress.Street = e.currentTarget.value
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ width: 2 }} />
                                                <div style={{ flex: 1 }}>
                                                    <TextField label={undefined} defaultValue={orderData.ShipAddress.HouseNo}
                                                        onChange={(e: any) => {
                                                            const currentData = dpdDataCorrected.OrderDataList[index]
                                                            if (currentData) {
                                                                currentData.ShipAddress.HouseNo = e.currentTarget.value
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                                                PLZ + Ort</div>
                                            <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                                                <div style={{ flex: 1 }}>
                                                    <TextField label={undefined} defaultValue={orderData.ShipAddress.ZipCode}
                                                        onChange={(e: any) => {
                                                            const currentData = dpdDataCorrected.OrderDataList[index]
                                                            if (currentData) {
                                                                currentData.ShipAddress.ZipCode = e.currentTarget.value
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ width: 2 }} />
                                                <div style={{ flex: 1 }}>
                                                    <TextField label={undefined} defaultValue={orderData.ShipAddress.City}
                                                        onChange={(e: any) => {
                                                            const currentData = dpdDataCorrected.OrderDataList[index]
                                                            if (currentData) {
                                                                currentData.ShipAddress.City = e.currentTarget.value
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SimpleModal>
                        </div>
                        <div>
                            {shipment.typename === 'Retoure' ?
                                <Button
                                    size='small'
                                    onClick={onClickReturnConfirm}
                                    isDisabled={!shipment.labelData}
                                    placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                                    <FaCheck />
                                </Button>
                                :
                                <Button
                                    size='small'
                                    onClick={onClickConfirm}
                                    isDisabled={!shipmentFinishable} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                    <FaCheck />
                                </Button>}
                        </div>
                        <div style={{ fontWeight: 'bold', color: Color.red, flex: 1, fontSize: '0.8em' }}>
                            <ToolTipPopup tipText={shipmentNumber} trigger={
                                <div>
                                    {shipment.typename === 'Retoure' ? 'Retoure' : ''} #{shipmentNumber.slice(0, 8)}...
                                </div>
                            } />
                        </div>
                        <div style={{ marginRight: '0.5em' }}>
                            <ToolTipPopup tipText={spToolTip} trigger={
                                <SelectField
                                    id='shipping-service-provider'
                                    label={'shipping-service-provider'}
                                    labelHidden={true}
                                    size='small'
                                    variation="quiet"
                                    value={serviceProvider}
                                    style={{ textAlign: 'left' }}
                                    onChange={(e) => updateserviceProvider((e.target.value))} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                >
                                    {shippingServiceProviders.map((provider, index: number) =>
                                        <option value={provider.name} key={index} disabled={shipment.typename === 'Retoure' && (provider.name === ServiceProvider.BURSPED || provider.name === ServiceProvider.INLINE)} >{provider.name}</option>
                                    )}
                                    {shipment.typename === 'Shipment' && <option value={'Blanko'}>Blanko</option>}
                                </SelectField>
                            } />
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', gap: '0.2em', flex: 1 }}>
                                {newLength === undefined ?
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setNewLength(`${ shipment.length }`)}>
                                        {length}
                                    </div>
                                    :
                                    <div style={{ width: '4em' }}>
                                        <TextField
                                            ref={input1Ref}
                                            style={{ height: '1.5em' }}
                                            label={undefined}
                                            variation='quiet'
                                            size='small'
                                            value={newLength}
                                            autoFocus
                                            onChange={(e: any) => setNewLength(e.target.value)}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    updateMeasurements()
                                                }
                                                if (e.key === 'Tab') {
                                                    setNewWidth(`${ shipment.width }`)
                                                    // focus next input with id height-input
                                                    e.preventDefault(); // Prevent default tab behavior
                                                    // @ts-ignore
                                                    input2Ref.current?.focus();
                                                }
                                            }} />
                                    </div>}
                                <div>cm x</div>
                                {newWidth === undefined ?
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setNewWidth(`${ shipment.width }`)}>
                                        {width}
                                    </div>
                                    :
                                    <div style={{ width: '4em' }}>
                                        <TextField
                                            ref={input2Ref}
                                            style={{ height: '1.5em' }}
                                            label={undefined}
                                            variation='quiet'
                                            size='small'
                                            value={newWidth}
                                            autoFocus
                                            onChange={(e: any) => setNewWidth(e.target.value)}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    updateMeasurements()
                                                }
                                                if (e.key === 'Tab') {
                                                    setNewHeight(`${ height }`)
                                                    // focus next input
                                                    e.preventDefault(); // Prevent default tab behavior
                                                    // @ts-ignore
                                                    input3Ref.current?.focus();
                                                }
                                            }} />
                                    </div>}
                                <div>cm x</div>
                                {newHeight === undefined ?
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setNewHeight(`${ height }`)}>
                                        {height}
                                    </div>
                                    :
                                    <div style={{ width: '4em' }}>
                                        <TextField
                                            ref={input3Ref}
                                            style={{ height: '1.5em' }}
                                            label={undefined}
                                            variation='quiet'
                                            size='small'
                                            value={newHeight}
                                            autoFocus
                                            onChange={(e: any) => setNewHeight(e.target.value)}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    updateMeasurements()
                                                }
                                                if (e.key === 'Tab') {
                                                    setNewWeight(`${ shipment.weight }`)
                                                    // focus next input
                                                    e.preventDefault(); // Prevent default tab behavior
                                                    // @ts-ignore
                                                    input4Ref.current?.focus();
                                                }
                                            }} />
                                    </div>}
                                <div>cm</div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '0.2em' }}>
                            {newWeight === undefined ?
                                // <ToolTipPopup
                                //     tipText={`abzüglich Toleranz: ${ getFakeWeight(weight) } Kg`}
                                //     trigger={<div
                                //         style={{ cursor: 'pointer' }}
                                //         onClick={() => setNewWeight(`${ shipment.weight }`)}>
                                //         {weight.toFixed(2)}
                                //     </div>}
                                // />
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setNewWeight(`${ shipment.weight }`)}>
                                    {weight.toFixed(2)}
                                </div>
                                :
                                <TextField
                                    ref={input4Ref}
                                    style={{ height: '1.5em', width: '5em' }}
                                    label={undefined}
                                    variation='quiet'
                                    size='small'
                                    value={newWeight}
                                    autoFocus
                                    onChange={(e: any) => setNewWeight(e.target.value)}
                                    onKeyDown={(e: any) => {
                                        if (e.key === 'Enter') {
                                            updateMeasurements()
                                        }
                                        if (e.key === 'Tab') {
                                            setNewLength(`${ shipment.length }`)
                                            // focus next input
                                            e.preventDefault(); // Prevent default tab behavior
                                            // @ts-ignore
                                            input1Ref.current?.focus();
                                        }
                                    }} />
                            }
                            <div>Kg</div>
                        </div>
                        <div>Gurtmaß: {gurtMass} cm</div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2em' }}>
                            <div style={{ color: costs && costs > shippingRevenues ? Color.orange : Color.green }}>
                                {newCosts === undefined ?
                                    // costs can only be adjusted for blank labels or BurSped
                                    <div onClick={() => (shipment.serviceProvider === ServiceProvider.BLANKO || shipment.serviceProvider === ServiceProvider.BURSPED) && setNewCosts(shipment.costs)}>Kosten: {costs} €</div>
                                    :
                                    <div style={{ display: 'flex', gap: '0.2em' }}>
                                        <div>Kosten:</div>
                                        <TextField
                                            style={{ height: '1.5em', width: '5em' }}
                                            label={undefined}
                                            variation='quiet'
                                            size='small'
                                            value={newWeight}
                                            autoFocus
                                            onChange={(e: any) => setNewCosts(e.target.value.replace(',', '.'))}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    updateCosts()
                                                }
                                            }} />
                                        <div>€</div>
                                    </div>
                                }
                            </div>
                            {shipment.serviceProvider === ServiceProvider.INLINE && inlinePalettePossible(weight, length, width, itemsCount) && <div style={{ fontSize: '10px' }}>gilt nur für Palette</div>}
                        </div>
                        <div style={{ display: 'flex' }}>
                            {/** there will be only one order for now */}
                            <div style={{ flex: 1 }}>Auftrag: {orderNumbers.map(orderNumber => ` #${ orderNumber }`)}</div>
                            {shipment.edited && <ToolTipPopup tipText={`Die Sendung wurde am ${ prettyDate(shipment.edited) } bearbeitet`} trigger={<IoMdInformationCircle />} />}
                        </div>
                    </div>
                </div>
            </ShipmentsDiv >
        </ThemeProvider >
    )
}

export default ShipmentCard